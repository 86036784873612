<template>
  <div>
    <PaymentStatus></PaymentStatus>
  </div>
</template>
<script>
import PaymentStatus from "@/views/commons/components/reports/tabs/payment-status/PaymentStatus.vue";

export default {
  components: {
    PaymentStatus,
  },
};
</script>